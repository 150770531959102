import React from "react";

export default function ConsentField({ data, changeHandler }) {
    return (
        <div className="flex flex-col mb-[25px] hq-consent">
            <label className="mb-[10px] text-primary text-[18px] font-[600]" htmlFor={data.id}>{data.isRequired ? <span></span> : ''}</label>
            <div className="flex flex-row flex-wrap">
                {
                    data.choices.map((c, i) => {
                        return (
                            <>
                                <input type={'checkbox'} id={`${data.id}-${c.value}`} name={data.id} onChange={(e) => { changeHandler(c.value, `${data.id}.${i + 1}`, data.type) }} />
                                <label htmlFor={`${data.id}-${c.value}`} dangerouslySetInnerHTML={{__html: data.checkboxLabel}} ></label>
                            </>
                        )
                    })
                }
            </div>
        </div>
    )
}