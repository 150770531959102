import React from "react";
import getValuesForKeys from "../../../functions/getValuesForKeys";


export default function RadioButton({ data, changeHandler, result }) {

        if (data.conditionalLogic.enabled) {
            let conditions = []

            data.conditionalLogic.rules.filter((r)=>{
                const val = getValuesForKeys(result, r.fieldId)
                if(val.includes(r.value)){
                    conditions = [...conditions, true]
                }
            })

            if(data.conditionalLogic.logicType == 'all'){
                if(conditions.length > 0 && conditions.every(v => v === true)){
                    return (
                        <div className="flex flex-col mb-[25px]">
                            <label className="mb-[10px] text-primary text-[18px] font-[600]" htmlFor={data.id}>{data.label}{data.isRequired ? <span></span> : ''}</label>
                            <div className="flex flex-row">
                                <fieldset id={data.id} className={"w-full flex flex-row flex-wrap"}>
                                    {
                                        data.choices.map((c, i) => {
                                            return (
                                                <div key={i}>
                                                    <input type={'radio'}  id={`${data.id}-${c.value}`} name={data.id} className="hidden" onChange={(e) => { changeHandler(c.value, data.id, data.type) }} />
                                                    <label className={`px-[21px] py-[17px] h-[58px] w-auto aspect-square mr-[10px] rounded-[8px] inline-flex items-center justify-center whitespace-nowrap cursor-pointer ${result[data.id] !== "" && result[data.id] == c.value ? "bg-accent text-white border-2 border-accent" : "text-text border-2 border-light"}`} htmlFor={`${data.id}-${c.value}`}>{c.text}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </fieldset>
                            </div>
                        </div>
                    )
                }
            }else{
                if(conditions.includes(true)){
                    return (
                        <div className="flex flex-col mb-[25px]">
                            <label className="mb-[10px] text-primary text-[18px] font-[600]" htmlFor={data.id}>{data.label}{data.isRequired ? <span></span> : ''}</label>
                            <div className="flex flex-row">
                                <fieldset id={data.id} className={"w-full flex flex-row flex-wrap"}>
                                    {
                                        data.choices.map((c, i) => {
                                            return (
                                                <div key={i}>
                                                    <input type={'radio'} id={`${data.id}-${c.value}`} name={data.id} className="hidden" onChange={(e) => { changeHandler(c.value, data.id, data.type) }} />
                                                    <label className={`px-[21px] py-[17px] h-[58px] w-auto aspect-square mr-[10px] rounded-[8px] inline-flex items-center justify-center whitespace-nowrap cursor-pointer ${result[data.id] !== "" && result[data.id] == c.value ? "bg-accent text-white border-2 border-accent" : "text-text border-2 border-light"}`} htmlFor={`${data.id}-${c.value}`}>{c.text}</label>
                                                </div>
                                            )
                                        })
                                    }
                                </fieldset>
                            </div>
                        </div>
                    )
                }
            }

        } else {
            return (
                <div className="flex flex-col mb-[25px]">
                    <label className="mb-[10px] text-primary text-[18px] font-[600]" htmlFor={data.id}>{data.label}{data.isRequired ? <span></span> : ''}</label>
                    <div className="flex flex-row">
                        <fieldset id={data.id} className={"w-full flex flex-row flex-wrap"}>
                            {
                                data.choices.map((c, i) => {
                                    return (
                                        <div key={i}>
                                            <input type={'radio'} id={`${data.id}-${c.value}`} name={data.id} className="hidden" onChange={(e) => { changeHandler(c.value, data.id, data.type) }} />
                                            <label className={`px-[21px] py-[17px] h-[58px] w-auto aspect-square mr-[10px] rounded-[8px] inline-flex items-center justify-center whitespace-nowrap cursor-pointer ${result[data.id] !== "" && result[data.id] == c.value ? "bg-accent text-white border-2 border-accent" : "text-text border-2 border-light"}`} htmlFor={`${data.id}-${c.value}`}>{c.text}</label>
                                        </div>
                                    )
                                })
                            }
                        </fieldset>
                    </div>
                </div>
            )
        }
}