import React, { useEffect, useState } from "react";

export default function DefaultInput({data, changeHandler}) {

    const [filled, setFilled] = useState(false)
    const [valid, setValid] = useState()
    const [error, setError] = useState(false)

    const fillHandler = (target, id, type) => {
        // if(valid){
            changeHandler(target, id, type)
        // }

    }

    useEffect(()=>{
        if(valid){
            setFilled(true)
        }else{
            setFilled(false)
        }
    },[valid])

    const validateHandler = (target, id, type) => {
        if(data.type == 'email'){
            var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if(target.match(mailformat)){
                setValid(true)
                setError(false)
                fillHandler(target, id, type)
            } else{
                setError(true)
            }
        }else if(target.length > 0){
            setValid(true)
            setError(false)
            fillHandler(target, id, type)
        }
    }

    return (
        <div className={`hq-form__field hq--form__field--default ${filled ? 'hq-form__field--validate' : ''} ${error ? 'hq-form__field--error' : ''}`}>
            {data.label ? <label className="hq-form__label" htmlFor={data.id}>{data.label}{data.isRequired ? <span>*</span> : ''}</label> : null}
            <input className="hq-form__field--input" placeholder={data.placeholder ? data.placeholder : null} type={data.type !== 'phone' ? data.type : 'tel'} id={data.id} required={data.isRequired ? 'required' : ''} onChange={(e) => { fillHandler(e.target.value, data.id, data.type) }} onBlur={(e)=>{validateHandler(e.target.value, data.id, data.type)}} ></input>
        </div>
    )
}