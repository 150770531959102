import { Link } from "gatsby";
import React from "react";

export default function Tag({titel, color, target}){
    if(target){
        return(
            <Link to={target}><span className={`hq-tag ${color}`}>{titel}</span></Link>
        )
    }else{
        return(
            <span className={`hq-tag ${color}`}>{titel}</span>
        )
    }
}