import { useStaticQuery, graphql } from "gatsby"

export const useNav = () =>{
   
    const data = useStaticQuery(graphql`
    query MegaMenuQuery{
        wpMenu(locations: {eq: HEADER_MENU}) {
            menuItems {
              nodes {
                id
                uri
                label
                nodeType
                menuSettings {
                  label
                  title
                  showOplossingen
                  showAanvullend
                  aanvullend {
                    items {
                      titel
                      link
                      label
                      beschrijving
                    }
                  }
                  oplossingen {
                    ... on WpOplossing {
                      id
                      title
                      uri
                    }
                  }
                }
                childItems {
                  nodes {
                    uri
                    label
                    description
                  }
                }
                parentId
              }
            }
          }
    }
    `)

    return data
}