import React from "react";
import useGoogleRating from "../../../functions/getGoogleRating";

export default function HeaderRating() {

    const star = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none"><path d="M7.25672 0.486272C7.53172 -0.162091 8.46832 -0.162091 8.74332 0.486274L10.3663 4.31303C10.4823 4.58637 10.7445 4.77313 11.0454 4.79678L15.2582 5.12799C15.9719 5.18411 16.2614 6.05763 15.7175 6.51446L12.5079 9.2107C12.2786 9.40331 12.1784 9.70552 12.2485 9.99343L13.2291 14.0249C13.3952 14.7079 12.6375 15.2478 12.0264 14.8818L8.41965 12.7214C8.16202 12.5671 7.83802 12.5671 7.5804 12.7214L3.9736 14.8818C3.3625 15.2478 2.60477 14.7079 2.77091 14.0249L3.75155 9.99343C3.82159 9.70552 3.72147 9.40331 3.49221 9.2107L0.28245 6.51446C-0.261375 6.05763 0.0280544 5.18411 0.741835 5.12799L4.9547 4.79678C5.25561 4.77313 5.51774 4.58637 5.63367 4.31303L7.25672 0.486272Z" fill="#ED7100" /></svg>
    const rating = useGoogleRating()

    let stars = []

    for (let i = 0; i < rating?.rating; i++) {
        stars.push(star)
    }

    if (rating) {
        return (
            <div className="hq-header__rating">
                <div className="hq-header__rating--stars">{stars.map((s, i) => <span key={i}>{s}</span>)}</div>
                <div className="hq-header__rating--text" itemScope itemType="http://schema.org/Organization">
                    <span itemProp="name" className="hidden">HQ Online</span>
                    <span itemProp="aggregateRating" itemScope itemType="http://schema.org/AggregateRating">
                        <span className="text-text"><span itemProp="ratingValue">{rating?.rating}</span> Sterren</span>
                        <span> door <span itemProp="reviewCount">{rating?.total}</span> beoordelingen</span>
                    </span>
                </div>
            </div>
        )
    }
}