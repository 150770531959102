import React, { useEffect, useState } from "react";

export default function TextArea({ data, changeHandler }) {

    const [filled, setFilled] = useState(false)
    const [length, setLength] = useState(0)

    const fillHandler = (target, id, type) => {
        setLength(target.length)
        changeHandler(target, id, type)
    }

    useEffect(()=>{
        if(length !== 0){
            setFilled(true)
        }else{
            setFilled(false)
        }
    },[length])

    return (
        <div className={`hq-form__field hq--form__field--textarea ${filled ? 'hq-form__field--validate' : ''}`}>
            <label className="hq-form__label" htmlFor={data.id}>{data.label}{data.isRequired ? <span>*</span> : ''}</label>
            <textarea className="hq-form__field--input" id={data.id} placeholder={data.placeholder ? data.placeholder : null} onChange={(e) => { fillHandler(e.target.value, data.id, data.type) }}></textarea>
        </div>
    )
}