import axios from "axios";

export default async function uploadFile(e, id){

    const user = process.env.GATSBY_WP_USER
    const secret = process.env.GATSBY_USER_SECRET

    var formData = new FormData();
    let file = e[0]
  
    formData.append('file', file);
    formData.append('title', `${id}-${file.name}`);

    let data = await axios.post(`${process.env.GATSBY_ENDPOINT}/wp-json/jwt-auth/v1/token`, {username: `${user}`, password: `${secret}`}).then(function(response){
  
     return axios.post(`${process.env.GATSBY_ENDPOINT}/wp-json/wp/v2/media`, formData, {headers: {'Authorization': `Bearer ${response.data.token}` }}).then(res => res.data.source_url).catch(function(err){return(err)})
  
    }).catch(function(err){
      console.error('Error', err)
      return(err)
    })
  return{data}
  }