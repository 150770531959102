import { useStaticQuery, graphql } from "gatsby"

export const useFooter = () =>{
   
    const data = useStaticQuery(graphql`
    query FooterQuery{
        wp {
            acfOptionsFooter {
                footer {
                  nieuwsbrief {
                    titel
                    tekst
                    formid
                  }
                  overons{
                    titel
                    tekst
                    primaryCta {
                      mobileLabel
                      link
                      label
                    }
                    secondaryCta {
                      link
                      label
                      mobileLabel
                    }
                    instagram{
                      url
                    }
                    facebook{
                      url
                    }
                    linkedin{
                      url
                    }
                    badges {
                      badge {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                    partners {
                      partner {
                        localFile {
                          childImageSharp {
                            gatsbyImageData
                          }
                        }
                      }
                    }
                  }
                  navigatie {
                    menu {
                      menu
                      titel
                    }
                  }
                  voorwaarden
                }
              }
        }
    }
    `)

    return data
}