import React, { useEffect, useState } from "react"
import Footer from "../../molecules/Footer"
import GlobalStyles from "../GlobalStyles"
import Accessibility from "../../atoms/Accessibility"
import PageContext from "../../../store/page-context"
import ScrollTop from "../../atoms/ScrollTop"
import MegaHeader from "../../molecules/Header/megaHeader"
import MiniHeader from "../../molecules/Header/miniHeader"
import { AnimatePresence } from "framer-motion"

const Layout = ({ children }) => {

  const [scale, setScale] = useState(1)
  const [scrolled, setScrolled] = useState(false)
  const [menuState, setMenuState] = useState()
  const [activeSub, setActiveSub] = useState()

  const scaleHandler = (e) => {
    setScale(e)
  }

  const subHandler = (e) => {
    setActiveSub(e)
    // setMenuState(false)
  }


  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 50) {
        setScrolled(true)
      } else if (window.pageYOffset == 0) {
        setScrolled(false)
      }
    }
  }, [])

  const mobileClickHandler = () => {
    setMenuState(!menuState)
    setActiveSub(undefined)
  }

  const closeMobileMenu = () => {
    setMenuState(false)
  }

  return (
    <PageContext.Provider
      value={{
        scale: scale,
        change: scaleHandler
      }}
    >
      <GlobalStyles rem={scale} />
      <MiniHeader subHandler={subHandler} />
      <MegaHeader scrolled={scrolled} mobileCloseHandler={closeMobileMenu} subHandler={subHandler} mobileClickHandler={mobileClickHandler} activeSub={activeSub} menuState={menuState} />
      <main>
        <AnimatePresence mode="wait">
          {children}
        </AnimatePresence>
      </main>
      <Accessibility />
      <ScrollTop />
      <Footer />
    </PageContext.Provider>
  )
}

export default Layout
