import { graphql, useStaticQuery } from "gatsby";
import { createGlobalStyle } from "styled-components"
import React from "react";
import Helmet from "react-helmet";
import favicon from "../../../images/favicon.ico"

export default function GlobalStyles({rem}) {

  const data = useStaticQuery(graphql`
    query settingsQuery{
        wp {
          acfOptionsTypografie {
            typografie {
              textfont
            }
          }
        acfOptionsKleuren {
            kleuren {
            header {
                background
                textcolor
            }
            footer {
                background
                textcolor
            }
            textcolors {
                textcolor
            }
            kleurpalet {
                accentcolor
                bgcolor
                primarycolor
                secondarycolor
                lightcolor
            }
            }
        }
        }
        }`)

  // Palet
  const primary = data.wp.acfOptionsKleuren.kleuren.kleurpalet.primarycolor
  const secondary = data.wp.acfOptionsKleuren.kleuren.kleurpalet.secondarycolor
  const accent = data.wp.acfOptionsKleuren.kleuren.kleurpalet.accentcolor
  const text = data.wp.acfOptionsKleuren.kleuren.textcolors.textcolor
  const background = data.wp.acfOptionsKleuren.kleuren.kleurpalet.bgcolor
  const light = data.wp.acfOptionsKleuren.kleuren.kleurpalet.lightcolor

  // Header
  const headerBackground = data.wp.acfOptionsKleuren.kleuren.header.background
  const headerText = data.wp.acfOptionsKleuren.kleuren.header.textcolor

  // Footer
  const footerBackground = data.wp.acfOptionsKleuren.kleuren.footer.background
  const footerText = data.wp.acfOptionsKleuren.kleuren.footer.textcolor

  // Fonts
  const textfont = data.wp.acfOptionsTypografie.typografie.textfont

  const GlobalStyle = createGlobalStyle`

    :root {
      --primary-color: ${primary};
      --secondary-color: ${secondary};
      --accent-color: ${accent};
      --text-color: ${text};
      --bg-color: ${background};
      --light-color: ${light};
      --light-gray-color: #E9E9E9;
      --light-bg-color: #FDFBFF;
      --text-font: '${textfont.replaceAll('+', ' ')}';
      --standard-font: ${rem};
    }
    html{
      font-size: 16px;
      font-family: var(--text-font)!important;
    }
    body{
      font-family: var(--text-font);
    }
    h1{
      font-weight: 900;
      font-style: normal;
    }
    h2{
      font-weight: 700;
      font-style: normal;
    }
    h3{
      font-weight: 700;
      font-style: normal;
    }
    h4{
      font-weight: 700;
      font-style: normal;
    }
    .headerClass{
      background-color: ${headerBackground};
      color: ${headerText};
    }
    .footerClass{
      background-color: ${footerBackground};
      color: ${footerText};
    }
    .footerClass h1,  .footerClass h2,  .footerClass h3,  .footerClass h4{
      color: ${footerText};
    }
  `

  return (
    <>
      <GlobalStyle />
      <Helmet>
        <meta name="icon" href={favicon} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        {/* <link rel="stylesheet" href="https://use.typekit.net/wzu6dgo.css"></link> */}
        <link href={`https://fonts.googleapis.com/css2?family=${textfont}:wght@100;200;300;400;500;600;700;800;900&display=swap`} rel="stylesheet" />
      </Helmet>
    </>
  )
}