import { Link } from "gatsby";
import React, { useState } from "react";
import { useNav } from "../../../hooks/useNav";
import Container from "../Container";
import Label from "../Label";
import Tag from "../Tag";
import { AnimatePresence, motion } from "framer-motion";
import { useHeaderSettings } from "../../../hooks/useHeaderSettings";

export default function Navigation({ width, state, subHandler, activeSub, closeMobile }) {

    const data = useNav()
    const chevron = <svg xmlns="http://www.w3.org/2000/svg" width="13" height="7" viewBox="0 0 13 7" fill="none"><path d="M11.56 1.13416L7.21333 5.48082C6.7 5.99416 5.86 5.99416 5.34667 5.48082L1 1.13416" stroke="#0E0E0E" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" /></svg>

    const headerSettings = useHeaderSettings()
    const miniMenu = headerSettings.wpMenu.menuItems.nodes

    const arrow = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M5.58185 3.45917L2.04102 7L5.58185 10.5408" stroke="#ED7100" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.9581 7H2.14062" stroke="#ED7100" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

  const navigationHandler = (e) =>{
    if(width < 787){
        closeMobile()
    }
    subHandler(e)
  }

    // if (width > 787) {
    return (
        <div>
            <nav className={`hq-navigation ${state ? 'hq-show' : ''}`}>
                <ul className="flex lg:flex-row flex-col hq-navigation__main">
                    {width < 787 ? <div className="inline px-[1rem]"><Label tekst={'Menu'} /></div> : null}
                    {data.wpMenu.menuItems.nodes.map((item, i) => {
                        if (item.parentId === null) {
                            return (
                                <li key={i} onClick={width <= 787 ? () => { item.childItems?.nodes.length > 0 ? subHandler(item.id) : navigationHandler(undefined) } : null}>
                                    <Link to={item.uri} activeClassName={'hq-navigation__main--active'} onMouseOver={item.childItems.nodes.length > 0 ? () => { subHandler(item.id) } : () => { subHandler(undefined) }}>
                                        {item.label}
                                        {item.childItems.nodes.length > 0 ? chevron : null}
                                    </Link>
                                </li>
                            )
                        }
                    })}
                </ul>
                {width < 787 ?
                        <div className="hq-header__mini--menu">
                            <nav>
                                <ul>
                                    {miniMenu.map((m, i) => {
                                        return (
                                            <li key={i} onClick={()=>{navigationHandler(undefined)}}><Link to={m.uri}>{m.label}</Link></li>
                                        )
                                    })}
                                </ul>
                            </nav>
                        </div>
                    : null}
                <AnimatePresence mode="wait">
                    {activeSub !== undefined ?
                        <motion.div initial={{ y: '0px', opacity: 0 }} animate={{ y: '0px', opacity: 1 }} exit={{ y: '0px', opacity: 0 }} transition={{ ease: "easeInOut", duration: .2 }} className="hq-navigation__sub">
                            <AnimatePresence mode="wait">
                                {data.wpMenu.menuItems.nodes.map((item, i) => {
                                    if (item.id == activeSub) {
                                        return (
                                            <motion.div initial={{ opacity: 0}} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ ease: "easeInOut", duration: .3 }} key={i} className={`hq-navigation__sub--item ${item.menuSettings.showOplossingen ? 'hq-navigation__sub--oplossingen' : item.menuSettings.showAanvullend ? 'hq-navigation__sub--oplossingen hq-navigation__sub--aanvullend' : ''}`} onMouseLeave={() => { subHandler(undefined) }} >
                                                <Container>
                                                    <div className="flex lg:flex-row flex-col justify-between">
                                                        <div className="hq-navigation__sub--menu">
                                                            {width < 787 ? <div className="hq-back" onClick={()=>{subHandler(undefined, true)}}>{arrow} Terug</div> : null}
                                                            {item.menuSettings.label ? <Label tekst={item.menuSettings.label} context={'hq-page__label'} /> : null}
                                                            <h2 className="hq-navigation__sub--title">{item.menuSettings.title}</h2>
                                                            <div className="hq-navigation__sub--grid">
                                                                {
                                                                    item.childItems.nodes.map((c, i) => {
                                                                        return (
                                                                            <div key={i} className="hq-navigation__sub--itemTile">
                                                                                <Link to={c.uri} onClick={() => { navigationHandler(undefined) }}>
                                                                                    <div className="hq-navigation__sub--link" >
                                                                                        {c.label}
                                                                                        <span>{chevron}</span>
                                                                                    </div>
                                                                                    <p className="hq-navigation__sub--desc">{c.description}</p>
                                                                                </Link>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        {
                                                            item.menuSettings.showOplossingen ?
                                                                <div className="hq-navigation__oplossingen">
                                                                    <h3 className="hq-navigation__oplossingen--title">Oplossingen</h3>
                                                                    <div className="hq-navigation__oplossingen--tagGrid">
                                                                        <Link to="/oplossingen" onClick={() => { navigationHandler(undefined) }}><Tag titel={'Bekijk alles'} color={'dark'} /></Link>
                                                                        {
                                                                            item.menuSettings.oplossingen.map((o, i) => {
                                                                                return (
                                                                                    <Link key={i} to={o.uri} onClick={() => { navigationHandler(undefined) }} className="hq-navigation__oplossingen--tag"><Tag titel={o.title} /></Link>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                : item.menuSettings.showAanvullend ?
                                                                    <div className="hq-navigation__aanvullend">
                                                                        <h3 className="hq-navigation__aanvullend--title">Aanvullend</h3>
                                                                        <div className="hq-navigation__aanvullend--grid">
                                                                            {
                                                                                item.menuSettings.aanvullend.items.map((o, i) => {
                                                                                    return (
                                                                                        <div key={i} className="hq-navigation__sub--itemTile">
                                                                                            <Link to={o.link} onClick={() => { navigationHandler(undefined) }} >
                                                                                                <div className="hq-navigation__sub--link">
                                                                                                    {o.titel}
                                                                                                    {o.label ? <div className="hq-navigation__aanvullend--label">{o.label}</div> : <span>{chevron}</span>}
                                                                                                </div>
                                                                                                <p className="hq-navigation__sub--desc">{o.beschrijving}</p>
                                                                                            </Link>
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    : null
                                                        }
                                                    </div>
                                                </Container>
                                            </motion.div>
                                        )
                                    }

                                })}
                            </AnimatePresence>
                        </motion.div>
                        : null}
                </AnimatePresence>
            </nav>
        </div>
    )
    // }
}