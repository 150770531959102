import React, { useEffect, useState } from "react";

export default function ScrollTop() {

    const [scroll, setScroll] = useState()

    useEffect(() => {

        window.onscroll = () => {
            setScroll(window.pageYOffset)
        }

    }, [])

    const clickHandler = () => {
        window.scrollTo(0, 0);
    }

    if (scroll > 500) {
        return (
            <div className="hq-scrolltop" onClick={clickHandler}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path d="M13.2807 10.0334L8.93404 5.6867C8.4207 5.17336 7.5807 5.17336 7.06737 5.6867L2.7207 10.0334" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
        )
    }
}