import { useEffect, useState } from "react";

const useGoogleRating = () => {
  const [googleRating, setGoogleRating] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/api/google-rating");
        if (response.ok) {
          const data = await response.json();
          setGoogleRating(data);
        } else {
          console.error("Error fetching Google rating data");
        }
      } catch (error) {
        console.error("Error fetching Google rating data", error);
      }
    };

    fetchData();
  }, []);

  return googleRating;
};

export default useGoogleRating;
