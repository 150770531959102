import axios from "axios";
import { graphql, navigate, useStaticQuery } from "gatsby";
import React, { useRef } from "react";
import { useEffect, useState } from "react";
import CheckBox from "../../atoms/FormFields/checkBox";
import ConsentField from "../../atoms/FormFields/consentField";
import DefaultInput from "../../atoms/FormFields/defaultInput";
import RadioButton from "../../atoms/FormFields/radioButton";
import FileInput from "../../atoms/FormFields/fileInput"
import TextArea from "../../atoms/FormFields/textArea";
import uploadFile from "../../../functions/uploadFile";
import Captcha from "../../atoms/FormFields/captcha";
import './form.css'

export default function Form({ id, styles, title }) {

    const [form, setForm] = useState()
    const [result, setResult] = useState({})
    const [submitted, setSubmitted] = useState(false)
    const [error, setError] = useState()
    const [message, setMessage] = useState()
    const [entryId, setEntryId] = useState()
    const [currPage, setCurrPage] = useState(0)
    const [fileQueue, setFileQueue] = useState([])
 
    const [captcha, setCaptcha] = useState(false)

    const captchaRef = useRef(null)

    const data = useStaticQuery(graphql`
        query AllPagesQuery{
            allWpPage{
                edges{
                    node{
                        slug
                        databaseId
                    }
                }
            }
        }`)

    useEffect(() => {
        const token = btoa(`${process.env.GATSBY_GF_KEY}:${process.env.GATSBY_GF_SECRET}`)
        if (window) {
            axios.get(`${process.env.GATSBY_GF_ENDPOINT}/forms/${id}`, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            }).then((res) => {
                setForm(res.data)
            })
        }
    }, [])

    useEffect(() => {

        const formObj = form !== undefined ? Object.values(form?.confirmations)[0] : null
        const token = btoa(`${process.env.GATSBY_GF_KEY}:${process.env.GATSBY_GF_SECRET}`)

        if (submitted && captcha) {
            axios.post(`${process.env.GATSBY_GF_ENDPOINT}/entries/${entryId}/notifications`, {

            }, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            }).then((res) => {
                if (formObj?.type == 'message' && res.status == 200) {
                    setMessage(formObj?.message)
                } else if (formObj?.type == 'page' && res.status == 200) {
                    let page = data.allWpPage.edges.filter((p) => p.node.databaseId == formObj?.pageId)
                    navigate(`/${page[0].node.slug}/`)
                }
            })
        }

    }, [submitted])

    useEffect(() => {
        if (entryId !== undefined) {
            setSubmitted(true)
        }
    }, [entryId])

    const changeHandler = (val, id, type) => {
        if (result[id] !== "" && type == 'checkbox') {
            setResult(prevState => {
                let obj = Object.assign({}, prevState)
                obj[id] = ""
                return { ...obj }
            })
        } else {
            setResult(prevState => {
                let obj = Object.assign({}, prevState)
                obj[id] = val
                return { ...obj }
            })
        }
    }

    const fileHandler = (val, id, type) => {
        setFileQueue([...fileQueue, {file: val.target.files, id: id}])    
    }

    const checkCaptcha = () => {
        console.log('captcha is being set')
        const captchaToken = captchaRef?.current?.getValue();

        let containsCaptcha = fields?.some(element => {
            return element.type === 'captcha';
        });

        if(captchaToken !== "" && containsCaptcha){
            axios.post('/api/google-captcha', { captchaToken }).then((res) => {
                setCaptcha(res.data)
            })
        }else if(captchaToken == "" && containsCaptcha){
            setCaptcha(false)
        }else{
            setCaptcha(true)
        }
}

    const submitHandler = (e) => {
        e.preventDefault()

        if(fileQueue.length > 0){
            fileQueue.forEach((f, i)=>{
                let file = f.file
                let url;
                let id = f.id;

                (async() => {
                    url = await uploadFile(file, id)
                    if(url.data.response == undefined){
                    setResult(prevState => {
                            let obj = Object.assign({}, prevState)
                            obj[id] = url.data
                            return { ...obj }
                        })

                        let filteredQueue = fileQueue.filter(item => item.id !== id)
                        setFileQueue(filteredQueue)

                        if(i + 1 === fileQueue.length){
                            checkCaptcha()
                        }

                    }else{ 
                        // console.log(url.data.response)
                    }
                })();
            })
        }else{
            checkCaptcha()
        }
    }

    const fields = form?.fields

    useEffect(() => {

        const obj = {}

        form?.fields.map((f, i) => {
            if (f.type !== 'checkbox') {
                obj[f.id] = ''
            } else {
                for (let i = 0; i < f.choices.length; i += 1) {
                    obj[`${f.id}.${i + 1}`] = ""
                }
            }
        })

        setResult(obj)

    }, [form])

    useEffect(() => {

        console.log(result, fileQueue, captcha)

        if (captcha) {
            const token = btoa(`${process.env.GATSBY_GF_KEY}:${process.env.GATSBY_GF_SECRET}`)

            const date = new Date()

            const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`

            let postData = {
                "form_id": id,
                "date_created": dateString,
                "created_by": 1,
                "status": "active",
            }

            axios.post(`${process.env.GATSBY_GF_ENDPOINT}/entries`, {
                ...postData,
                ...result
            }, {
                headers: {
                    'Authorization': `Basic ${token}`
                }
            }).then((res) => {
                res.status == 201 ?
                    setEntryId(res.data.id)
                : setError('Er is iets mis gegaan, probeer het nog eens.')
            })

        } else if(!captcha && fields !== undefined) {
            setError('Vul de reCaptcha in')
        }

    }, [captcha])

    const steps = fields?.reduce((r, o) => {
        if (o.type === 'section') return [...r, []];
        if (!r.length) return [[o]];

        r[r.length - 1].push(o);
        return r;
    }, [])

    return (
        <div className={`${styles}`}>
            {title ? <h3 className="mb-[20px]">{form?.title}</h3> : null}
            {
                !submitted && fields !== undefined ?
                    <form onSubmit={submitHandler}>
                        {
                            steps[currPage]?.map((f) => {
                                switch (f.type) {
                                    case 'textarea':
                                        return (
                                            <TextArea data={f} changeHandler={changeHandler} key={f.id} />
                                        )
                                    case 'radio':
                                        return (
                                            <RadioButton data={f} changeHandler={changeHandler} result={result} key={f.id} />
                                        )
                                    case 'checkbox':
                                        return (
                                            <CheckBox data={f} changeHandler={changeHandler} result={result} key={f.id} />
                                        )
                                    case 'section':
                                        return (
                                            <div key={f.id}>Section</div>
                                        )
                                    case 'consent':
                                        return (
                                            <ConsentField data={f} changeHandler={changeHandler} key={f.id} />
                                        )
                                    case 'captcha':
                                        return (
                                            <Captcha captchaRef={captchaRef} />
                                        )
                                    case 'fileupload':
                                        return(
                                            <FileInput data={f} changeHandler={fileHandler} />
                                        )
                                    default:
                                        return (
                                            <DefaultInput data={f} changeHandler={changeHandler} key={f.id} />
                                        )
                                }
                            })
                        }
                        {
                            currPage !== (steps.length - 1) && currPage > 0 ?
                                <div className="flex justify-between">
                                    <div className="bg-background rounded-t-[27px] rounded-r-[27px] px-[23px] py-[19px] text-primary cursor-pointer inline" onClick={() => { setCurrPage(currPage - 1) }}>Vorige</div>
                                    <div className="bg-accent rounded-t-[27px] rounded-r-[27px] px-[23px] py-[19px] text-white cursor-pointer inline" onClick={() => { setCurrPage(currPage + 1) }}>Volgende</div>
                                </div>
                                : currPage == 0 && steps.length > 1 ?
                                    <div className="bg-accent rounded-t-[27px] rounded-r-[27px] px-[23px] py-[19px] text-white cursor-pointer inline-block" onClick={() => { setCurrPage(currPage + 1) }}>Volgende</div>
                                    :
                                    <div className="flex justify-between">
                                        {steps.length > 1 ? <div className="bg-background rounded-t-[27px] rounded-r-[27px] px-[23px] py-[19px] text-primary cursor-pointer inline" onClick={() => { setCurrPage(currPage - 1) }}>Vorige</div> : null}
                                        <input type="submit" value={form?.button.text} className="hq-form__submit"></input>
                                    </div>
                        }
                    </form>
                    : submitted && message !== undefined ?
                        <p className="formMessage">{message}</p>
                        : !submitted && error !== undefined ?
                            <p className="formError">{error}</p>
                            :

                            <div className="">
                                <div className="flex flex-col mb-4">
                                    <div className="animated-label animated-background mb-2"></div>
                                    <div className="w-full animated-background"></div>
                                </div>
                                <div className="flex flex-col mb-4">
                                    <div className="animated-label animated-background mb-2"></div>
                                    <div className="w-full animated-background"></div>
                                </div>
                                <div className="flex flex-col mb-4">
                                    <div className="animated-label animated-background mb-2"></div>
                                    <div className="w-full animated-background"></div>
                                </div>
                                <div className="flex flex-col mb-4">
                                    <div className="animated-label animated-background mb-2"></div>
                                    <div className="w-full animated-background animated-textarea"></div>
                                </div>
                                <div className="animated-submit animated-background mb-2"></div>
                            </div>
            }
        </div>
    )
}