import React from "react";

export default function CheckBox({ data, changeHandler, result }) {
    return (
        <div className="flex flex-col mb-[25px]">
            <label className="mb-[10px] text-primary text-[18px] font-[600]" htmlFor={data.id}>{data.label}{data.isRequired ? <span></span> : ''}</label>
            <div className="flex flex-row flex-wrap">
                <fieldset id={data.id} className="flex flex-row flex-wrap">
                    {
                        data.choices.map((c, i) => {
                            return (
                                <div key={i}>
                                    <input type={'checkbox'} id={`${data.id}-${c.value}`} name={data.id} className="hidden" onChange={(e) => { changeHandler(c.value, `${data.id}.${i + 1}`, data.type) }} />
                                    <label className={`px-[21px] h-[60px] mr-[10px] mb-[10px] rounded-[8px] inline-flex items-center justify-center cursor-pointer ${result[`${data.id}.${i + 1}`] !== "" ? "bg-accent text-white border-2 border-accent" : "text-text border-2 border-light"}`} htmlFor={`${data.id}-${c.value}`}>{c.text}</label>
                                </div>
                            )
                        })
                    }
                </fieldset>
            </div>
        </div>
    )
}