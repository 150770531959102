import React, { useEffect, useState } from "react";
import Container from "../../atoms/Container";
import { useFooter } from "../../../hooks/useFooter";
import Form from "../Form";
import PrimaryButton from "../../atoms/Buttons/ButtonWithArrow";
import insta from "../../../images/insta.svg"
import fb from "../../../images/facebook.svg"
import li from "../../../images/linkedin.svg"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import './footer.css'
import Cookiebar from "../../atoms/CookieBar";

export default function Footer(){

    const data = useFooter()
    const nieuwsbrief = data.wp.acfOptionsFooter.footer.nieuwsbrief
    const about = data.wp.acfOptionsFooter.footer.overons
    const menu = data.wp.acfOptionsFooter.footer.navigatie

    const voorwaarden = data.wp.acfOptionsFooter.footer.voorwaarden
    
    const [width, setWidth] = useState()
  
    useEffect(()=>{
      if(window !== undefined){
        setWidth(window.innerWidth)
      }
    },[])

    const year = new Date().getFullYear()

    return(
        <footer className="footerClass hq-footer">
            {/* <Cookiebar /> */}
            <Container styles={'flex lg:flex-row flex-col justify-between hq-footer__nieuwsbrief'}>
                <div className="hq-footer__nieuwsbrief--content">
                    <h2 className="hq-footer__nieuwsbrief--titel">{nieuwsbrief.titel}</h2>
                    <div className="hq-footer__nieuwsbrief--tekst" dangerouslySetInnerHTML={{__html: nieuwsbrief.tekst}}></div>
                </div>
                <div className="hq-footer__nieuwsbrief--content hq-footer__nieuwsbrief--form">
                    <Form id={nieuwsbrief.formid} />
                </div>
            </Container>
            <Container styles={'hq-footer__nav'}>
                <div className="hq-footer__about">
                    <h4 className="hq-footer__about--title">{about.titel}</h4>
                    <div className="hq-footer__about--tekst" dangerouslySetInnerHTML={{__html: about.tekst}}></div>
                    <div className="hq-footer__about--buttons">
                        <PrimaryButton target={about.primaryCta.link} label={width > 787 ? about.primaryCta.label : about.primaryCta.mobileLabel} color={'dark'} />
                        {/* <PrimaryButton target={about.secondaryCta.link} label={width > 787 ? about.secondaryCta.label : about.secondaryCta.mobileLabel} color={'light'} /> */}
                    </div>
                    <div className="hq-footer__about--socials">
                        {about.instagram ? <a href={about.instagram.url} target="_blank"><img aria-hidden="true" src={insta} /></a> : null}
                        {about.facebook ? <a href={about.facebook.url} target="_blank"><img aria-hidden="true" src={fb} /></a> : null}
                        {about.linkedin ? <a href={about.linkedin.url} target="_blank"><img aria-hidden="true" src={li} /></a> : null}
                    </div>
                </div>
                <div className="hq-footer__navigation">
                    {
                        menu.map((m, i)=>{
                            return(
                                <div key={i} className="hq-footer__navigation--column">
                                    <h4 className="hq-footer__navigation--title">{m.menu.titel}</h4>
                                    <div className="hq-footer__navigation--menu" dangerouslySetInnerHTML={{__html: m.menu.menu}}></div>
                                </div>
                            )
                        })
                    }
                </div>
            </Container>
            <Container styles={'flex justify-between'}>
                <div className="hq-footer__about--badges">
                        {
                            about.badges.map((b, i)=>{
                                const img = getImage(b.badge.localFile.childImageSharp.gatsbyImageData)
                                return(
                                    <GatsbyImage key={i} alt="" image={img} className="hq-footer__about--badge" />
                                )
                            })
                        }
                </div>
                <div className="hq-footer__about--badges hq-footer__about--partners">
                        {
                            about.partners.map((b, i)=>{
                                const img = getImage(b.partner.localFile.childImageSharp.gatsbyImageData)
                                return(
                                    <GatsbyImage key={i} alt="" image={img} className="hq-footer__about--badge" />
                                )
                            })
                        }
                </div>
            </Container>
            <Container styles={'hq-footer__privacy'}>
                <div className="hq-footer__privacy__copyright">
                    &copy; {year} HQ Online B.V. Alle rechten voorbehouden
                    <span>KvK nr. 86831240</span>
                </div>
                <div className="hq-footer__privacy--voorwaarden" dangerouslySetInnerHTML={{__html: voorwaarden}}></div>
            </Container>
        </footer>
    )
}