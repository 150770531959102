import React from "react";
import Container from "../../atoms/Container";
import HeaderRating from "../../atoms/HeaderRating";
import { useHeaderSettings } from "../../../hooks/useHeaderSettings";
import { Link } from "gatsby";

export default function MiniHeader({subHandler}) {

    const headerSettings = useHeaderSettings()
    const miniMenu = headerSettings.wpMenu.menuItems.nodes

    return (
        <>
        {headerSettings.wp.acfOptionsHeader.header.notice ? <div className="hq-header__notice" dangerouslySetInnerHTML={{__html: headerSettings.wp.acfOptionsHeader.header.notice}}></div> : null}
        <div className="hq-header__mini" onMouseEnter={() => { subHandler(undefined) }}>
            <Container styles={'flex justify-between'}>
                <HeaderRating />
                <div className="hq-header__mini--menu">
                    <nav>
                        <ul>
                            {miniMenu.map((m, i) => {
                                return (
                                    <li key={i}><Link to={m.uri}>{m.label}</Link></li>
                                )
                            })}
                        </ul>
                    </nav>
                </div>
            </Container>
        </div>
        </>
    )
}