import { Link } from "gatsby";
import React, { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion, cubicBezier } from "framer-motion";

export default function PrimaryButton({ label, target, styles, iconStyles, color, icon, iconHover, iconPosition, clickHandler, width }) {

    const [conditionalStyles, setConditionalStyles] = useState()
    const [conditionalIconStyles, setConditionalIconStyles] = useState()
    const [isAnimating, setIsAnimating] = useState(false)

    const defaultIcon = <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none" className={`${iconStyles} ${conditionalIconStyles}`}><path d="M4.47517 9.96001L7.73518 6.70001C8.12018 6.31501 8.12018 5.68501 7.73518 5.30001L4.47518 2.04001" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" className={`${iconStyles} ${conditionalIconStyles} transition-all duration-150 delay-150 ease-in-out`} /></svg>

    const customEasingIn = cubicBezier(0.55, 0.1, 0.8, 0.2);
    const customEasingOut = cubicBezier(0.2, 0.8, 0.55, 0.9);

    useEffect(() => {
        if (color == 'dark') {
            setConditionalStyles('bg-secondary text-white')
            setConditionalIconStyles('stroke-white')
        } else if (color == 'light') {
            setConditionalStyles('bg-white text-secondary hover:text-white')
            setConditionalIconStyles('group-hover:stroke-white stroke-secondary')
        }
    }, [])

    if(!target?.includes('https://')){
        return (
            <div onClick={clickHandler} onMouseEnter={()=>{setIsAnimating(true)}} onMouseLeave={()=>{setIsAnimating(false)}}>
                <Link to={target} className={`${styles} ${conditionalStyles} hq-button__primary group`}>
                    <div className="z-[10] flex items-center transition-all duration-150 delay-150">
                        {icon && iconPosition == 'before' ? <img src={isAnimating && iconHover ? iconHover : icon} className={`${label ? 'mr-[0.5rem]' : ''}`} /> : null}
                        {label}
                        {icon && iconPosition == 'after' ? <img src={isAnimating && iconHover ? iconHover : icon} /> : icon && iconPosition !== 'after' ? null : defaultIcon}
                    </div>
                    <AnimatePresence mode="wait">
                        {isAnimating ?
                            <motion.div initial={{y: '100%'}} animate={{y: '0%'}} exit={{y: '-100%'}} transition={{ease: 'easeInOut', duration: .4}} className={`hq-button__fill`}></motion.div>
                        : null
                        }
                    </AnimatePresence>
                </Link>
            </div>
        )
    } else {
        return (
            <div onClick={clickHandler} onMouseEnter={()=>{setIsAnimating(true)}} onMouseLeave={()=>{setIsAnimating(false)}}>
                <a href={target} target="__blank" className={`${styles} ${conditionalStyles} hq-button__primary group`}>
                    <div className="z-[10] flex items-center transition-all duration-150 delay-150">
                        {icon && iconPosition == 'before' ? <img src={isAnimating && iconHover ? iconHover : icon} className={`${label ? 'mr-[0.5rem]' : ''}`} /> : null}
                        {label}
                        {icon && iconPosition == 'after' ? <img src={isAnimating && iconHover ? iconHover : icon} /> : icon && iconPosition !== 'after' ? null : defaultIcon}
                    </div>
                    <AnimatePresence mode="wait">
                        {isAnimating ?
                            <motion.div initial={{y: '100%'}} animate={{y: '0%'}} exit={{y: '-100%'}} transition={{ease: 'easeInOut', duration: .4}} className={`hq-button__fill`}></motion.div>
                        : null
                        }
                    </AnimatePresence>
                </a>
            </div>
        )
    }
    
}